.custom-table {
  font-size: 11pt;
}

.active-row:hover .rs-table-cell {
  background-color: #fafafa;
}

/* Hide action row header */
.rs-table-row-header .rs-table-cell-group-fixed-right {
  width: 0 !important;
  padding: 0 !important;  
}

.custom-table-action-header .rs-table-cell-header {
  display: none;
}

.custom-table-action-header > div {
  display: none;
}

/* Display action cell on hover */
.active-row:not(:hover) .rs-table-cell-group-fixed-right {
  visibility: hidden;
}

.active-row:not(:hover) .rs-table-cell-group .custom-table-action-cell {
  visibility: hidden;
}

.custom-table-action-cell {
  background-color: #fff !important;
}

.active-row:hover .rs-table-cell-group-fixed-right {
  position: absolute !important;
  right: 0;
}