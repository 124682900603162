#auth__container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../../../public/homepageimage.jpg.webp");
  background-size: cover;
  position: absolute;
}

.auth__logo {
  width: 220px;
  margin: 10px;
}